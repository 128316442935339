<template>
  <div
    v-if="attachments.length === 0"
    class="attachment-table__empty"
  >
    <p>
      {{ $t('legalReports.bdese.attachDocuments.noAttachment') }}
    </p>
  </div>
  <ReflectTable
    v-else
    :columns="columns"
    :with-vertical-borders="false"
    :without-full-height-scroll="true"
    :is-in-modal="isInAttachmentManager"
    class="attachment-table"
  >
    <tr
      v-for="attachment in attachments"
      :key="attachment.id"
    >
      <td class="attachment-table__cell attachment-table__filename">
        <ReflectCheckbox
          v-if="isInSelectionMode"
          :key="attachment.id + selectedAttachmentIds.toString()"
          :name="attachment.id"
          :value="selectedAttachmentIds.includes(attachment.attachmentId)"
          theme="secondary"
          class="filename__checkbox"
          @toggle="
            $emit('toggle-attachment-selection', attachment.attachmentId)
          "
        />
        <SettingsFilenameAndSize
          :filename="attachment.filename"
          :size="attachment.size"
        />
      </td>
      <td class="attachment-table__cell attachment-table__uploadedAt">
        {{ formatDate(attachment.uploadedAt) }}
      </td>
      <td class="attachment-table__cell attachment-table__action">
        <button
          v-if="!isInAttachmentManager || isInSelectionMode"
          class="cell__edit-button"
          @click="downloadAttachmentFile(attachment.id)"
        >
          <i class="edit-button__icon icon-download" />
        </button>
        <button
          v-else
          class="cell__edit-button"
          @click="event => openEditMenu(event, attachment.id)"
        >
          <i class="edit-button__icon icon-dots" />
        </button>
      </td>
    </tr>
  </ReflectTable>
  <Teleport
    v-if="isEditMenuOpen"
    to="#action"
  >
    <ReflectOverlay @cancel="closeEditMenu">
      <div
        v-if="isEditMenuOpen && attachmentToEditId"
        class="attachment-table__edit-module"
        :style="{
            top: editMenuPosition!.y + 'px',
            right: `calc(100% - ${editMenuPosition!.x}px)`
          }"
      >
        <button
          class="edit-module__button"
          @click="downloadAttachmentFile(attachmentToEditId)"
        >
          {{ $t('common.fileManagement.download') }}
        </button>
        <button
          class="edit-module__button"
          @click="$emit('replace-attachment', attachmentToEditId)"
        >
          {{ $t('common.fileManagement.replace') }}
        </button>
        <button
          class="edit-module__button edit-module__button--with-warning"
          @click="$emit('delete-attachment', attachmentToEditId)"
        >
          {{ $t('common.fileManagement.remove') }}
        </button>
      </div>
    </ReflectOverlay>
  </Teleport>
</template>

<script lang="ts">
import { FETCH_DOWNLOAD_BDESE_ATTACHMENT_QUERY } from '~/gql/legalReports/bdese/attachments/downloadBdeseAttachmentQuery'
import { formatDate } from '~/services/date'
import { downloadFile } from '~/services/file'
import type { BdeseAttachment } from '~/types/bdese'
import { JustifyContent } from '~/types/table'

const COLUMNS = {
  FILENAME: 'filename',
  UPLOADED_AT: 'uploadedAt',
  EDIT: null
} as const

export default {
  name: 'AttachmentTable',
  props: {
    attachments: {
      type: Array as PropType<BdeseAttachment[]>,
      required: true
    },
    isInAttachmentManager: {
      type: Boolean,
      default: false
    },
    selectedAttachmentIds: {
      type: Array as PropType<string[]>,
      default: null
    }
  },
  emits: {
    'toggle-attachment-selection'(_: string) {
      return true
    },
    'delete-attachment'(_: string) {
      return true
    },
    'replace-attachment'(_: string) {
      return true
    }
  },
  setup() {
    return {
      formatDate
    }
  },
  data(): {
    isEditMenuOpen: boolean
    editMenuPosition: { x: number; y: number } | undefined
    attachmentToEditId: string | null
  } {
    return {
      isEditMenuOpen: false,
      editMenuPosition: undefined,
      attachmentToEditId: null
    }
  },
  computed: {
    columns() {
      return [
        {
          key: COLUMNS.FILENAME,
          value: this.$t('common.fileManagement.filename'),
          countDistinct: this.attachments.length
        },
        {
          key: COLUMNS.UPLOADED_AT,
          value: this.$t('common.fileManagement.uploadedAt'),
          justifyHeader: JustifyContent.CENTER
        },
        {
          key: COLUMNS.EDIT,
          value: null
        }
      ]
    },
    isInSelectionMode() {
      return this.selectedAttachmentIds !== null
    }
  },
  methods: {
    async downloadAttachmentFile(id: string) {
      const downloadLink = (
        await this.$apiGqlClient.query({
          query: FETCH_DOWNLOAD_BDESE_ATTACHMENT_QUERY,
          variables: {
            id
          }
        })
      ).data.downloadBdeseAttachment

      downloadFile(downloadLink)
    },
    closeEditMenu() {
      this.isEditMenuOpen = false
      this.attachmentToEditId = null
    },
    openEditMenu(event: Event, id: string) {
      const position = (
        event.currentTarget as HTMLDivElement
      ).getBoundingClientRect()

      this.editMenuPosition = { x: position.left, y: position.y }
      this.isEditMenuOpen = true
      this.attachmentToEditId = id
    }
  }
}
</script>

<style scoped lang="scss">
.attachment-table {
  &__empty {
    flex: 1;
    color: $text-tertiary;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 160px;
    border: 1px solid $border-ternary;
    border-radius: 8px;
  }

  &__cell {
    @include font-text;
    @include font-size($font-size-mini);
    vertical-align: middle;
    padding: $margin-mini $margin-regular;
    box-sizing: border-box;
  }

  &__edit-module {
    @include block-shadow;
    position: fixed;
    display: flex;
    flex-direction: column;
    margin-left: $margin-mini;
    z-index: 1;
    background-color: $bg-primary;
    border-radius: 8px;
    overflow: hidden;

    .edit-module {
      &__button {
        display: flex;
        align-items: center;
        padding: $margin-mini;
        color: $text-primary;
        @include font-text;
        @include font-size($font-size-regular, 24px);
        cursor: pointer;

        &:hover {
          color: $text-inverse;
          background-color: $button-primary;
        }

        &--with-warning {
          color: $text-warning;
        }
      }
    }
  }

  &__filename {
    display: flex;
    height: fit-content;

    .filename {
      &__checkbox {
        align-self: center;
        height: fit-content;
        margin-right: $margin-mini;
      }
    }
  }

  &__uploadedAt {
    color: $text-tertiary;
    width: 140px;
    text-align: center;
  }

  &__action {
    width: 54px;
  }

  .cell {
    &__edit-button {
      @include font-icon;
      @include font-size($font-size-intermediate);
      cursor: pointer;
    }
  }
}
</style>
